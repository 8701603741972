.btn-onex-primary {
    cursor: pointer;
    position: relative;
    border: none;
    background-color: #9fa2a3;
    border-radius: 0.8rem;
    color: white;

    font-weight: 500;
    word-wrap: break-word;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -1px;
        border-radius: inherit;
        background: linear-gradient(to left, #8EE7F5, #602080 );
    }
}
/* .Mui-foucsed{
    background:transparent!important;
} */
.oqYQj,
.hGxECB {
    cursor: pointer;
    background-color: transparent!important;
}

.oqYQj .icon {fill: #8EE7F5 !important;
}

.Icytq {
    padding: 0 !important;
    border: none !important;
    /* border: 2px solid #602080 !important; */
    /* border-right: 2px solid #602080 !important; */
    /* border-bottom: 2px solid #602080 !important; */
}

.Icytq span {
    background: #050d21 !important;
}

.hexGonButton {
    cursor: pointer;
    height: 50px;
    min-width: 50px!important;
    clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
    background: linear-gradient(to left, #8EE7F5, #602080);
}
.hexGonButton  svg path{
    fill:white
}
.ChatWrapper:hover > .cancelBtn{
    visibility: hidden;
}
.Mui-focused{
    color: #ffff!important;
    background: transparent!important;
}
#graph-graph-wrapper{
    height: 100%!important;
}
svg[name="svg-container-graph"]{
    width: 100%!important;
    height: calc(80vh - 100px)!important;
}
/* #graph-graph-wrapper, #graph{
    height:100%;
    width:100%
} */
/* .PluginCatalogGraphCustomNode-node-6574.secondary, 
.PluginCatalogGraphCustomNode-node-11614.secondary, 
.PluginCatalogGraphCustomNode-node-6665.secondary,
.PluginCatalogGraphCustomNode-node-6989.secondary{
    fill:#602080!important;
    stroke:#602080!important;
} */